import React from "react";
import { graphql } from "gatsby";
import CampaignPage4Hero from "../components/page-components/Campaigns/Page4/Hero";
import { Styling } from "../components/page-components/Campaigns/Page2/styled.js";
export default function Campaigns4({ data }) {
  const { pageData } = data.wpCampaign;
  return (
    <Styling>
      <CampaignPage4Hero data={pageData} />
    </Styling>
  );
}

export const query = graphql`
  query ($id: String!) {
    wpCampaign(id: { eq: $id }) {
      seo {
        metaDesc
        title
      }
      slug
      pageData: acf_campaigns {
        page4Heading
        page4Text
        page4Video
      }
    }
  }
`;
