import React, { useRef, useState } from "react";
import { Styling } from "./../../Page2/Hero/styled.js";
import { Styling as Styling2 } from "./styled";
import Play from "../../../../../images/play.inline.svg";
import parse from "html-react-parser";

export default function CampaignPage4Hero({ data, slug }) {
  const trailerVid = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayVideo = () => {
    if (isPlaying === false) {
      trailerVid.current.play();
      setIsPlaying(true);
    } else {
      trailerVid.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <Styling2>
      <Styling>
        <div className="wrapper">
          <h1 className="heading">{data.page4Heading}</h1>
          <div className="text">{parse(data.page4Text)}</div>
          <div className="video-wrapper">
            <div className={isPlaying ? `play playing` : `play paused`}>
              <button onClick={() => handlePlayVideo()}>
                <Play />
              </button>
            </div>
            <video playsInline ref={trailerVid} src={data.page4Video}></video>
          </div>
        </div>
      </Styling>
    </Styling2>
  );
}
